// Breakpoints
$xsMax: 575px;
$smMin: 576px;
$smMax: 767px;
$mdMin: 768px;
$mdMax: 991px;
$lgMin: 992px;
$lgMax: 1199px;
$xlgMin: 1200px;

// Colors
$mainColor: #4696e5;
$lightHoverColor: #eee;
$lightGrey: #ddd;
$dangerColor: #dc3545;

// Misc
$headerHeight: 57px;
