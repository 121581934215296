@import '../utils/base';

.create-server {
  padding: 40px 20px;
}

.create-server__label {
  font-weight: 700;
  cursor: pointer;

  @media (min-width: $mdMin) {
    text-align: right;
  }
}

.create-server__csv-select {
  position: absolute;
  left: -9999px;
  top: -9999px;
}
